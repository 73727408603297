import { SVGProps } from "react";
export default function IconArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.7335 13.4676L9.19818 9.99553L12.7335 6.52349C13.0888 6.1745 13.0888 5.61074 12.7335 5.26174C12.3781 4.91275 11.8041 4.91275 11.4487 5.26174L7.26651 9.36913C6.91116 9.71812 6.91116 10.2819 7.26651 10.6309L11.4487 14.7383C11.8041 15.0872 12.3781 15.0872 12.7335 14.7383C13.0797 14.3893 13.0888 13.8166 12.7335 13.4676Z" />
    </svg>
  );
}
