import { SVGProps } from "react";
export default function IconEqualizer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1215)">
        <path d="M8.33337 16.6667H11.6667V3.33334H8.33337V16.6667ZM3.33337 16.6667H6.66671V10H3.33337V16.6667ZM13.3334 7.50001V16.6667H16.6667V7.50001H13.3334Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1215">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
