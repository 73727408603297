import { SVGProps } from "react";
export default function IconCancel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_706)">
        <path d="M10 2C5.576 2 2 5.576 2 10C2 14.424 5.576 18 10 18C14.424 18 18 14.424 18 10C18 5.576 14.424 2 10 2ZM14 12.872L12.872 14L10 11.128L7.128 14L6 12.872L8.872 10L6 7.128L7.128 6L10 8.872L12.872 6L14 7.128L11.128 10L14 12.872Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_706">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
