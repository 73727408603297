import { SVGProps } from "react";
export default function IconTrendingUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1179)">
        <path d="M13.3333 5L15.2416 6.90833L11.175 10.975L7.84163 7.64167L1.66663 13.825L2.84163 15L7.84163 10L11.175 13.3333L16.425 8.09167L18.3333 10V5H13.3333Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1179">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
