import { SVGProps } from "react";
export default function IconCancelRound(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_938)">
        <path d="M10 2C5.576 2 2 5.576 2 10C2 14.424 5.576 18 10 18C14.424 18 18 14.424 18 10C18 5.576 14.424 2 10 2ZM13.44 13.44C13.128 13.752 12.624 13.752 12.312 13.44L10 11.128L7.688 13.44C7.376 13.752 6.872 13.752 6.56 13.44C6.248 13.128 6.248 12.624 6.56 12.312L8.872 10L6.56 7.688C6.248 7.376 6.248 6.872 6.56 6.56C6.872 6.248 7.376 6.248 7.688 6.56L10 8.872L12.312 6.56C12.624 6.248 13.128 6.248 13.44 6.56C13.752 6.872 13.752 7.376 13.44 7.688L11.128 10L13.44 12.312C13.744 12.616 13.744 13.128 13.44 13.44Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_938">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
