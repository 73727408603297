import { SVGProps } from "react";
export default function IconLogout(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3091_50)">
        <path d="M9.16663 5.83333L7.99996 7L10.1666 9.16667H1.66663V10.8333H10.1666L7.99996 13L9.16663 14.1667L13.3333 10L9.16663 5.83333ZM16.6666 15.8333H9.99996V17.5H16.6666C17.5833 17.5 18.3333 16.75 18.3333 15.8333V4.16667C18.3333 3.25 17.5833 2.5 16.6666 2.5H9.99996V4.16667H16.6666V15.8333Z" />
      </g>
      <defs>
        <clipPath id="clip0_3091_50">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
