import { SVGProps } from "react";
export default function Visibility(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_852_14285)">
        <rect width="20" height="20" fill="white" />
        <path d="M9.99998 4.99992C13.1583 4.99992 15.975 6.77492 17.35 9.58325C15.975 12.3916 13.1583 14.1666 9.99998 14.1666C6.84165 14.1666 4.02498 12.3916 2.64998 9.58325C4.02498 6.77492 6.84165 4.99992 9.99998 4.99992ZM9.99998 3.33325C5.83331 3.33325 2.27498 5.92492 0.833313 9.58325C2.27498 13.2416 5.83331 15.8333 9.99998 15.8333C14.1666 15.8333 17.725 13.2416 19.1666 9.58325C17.725 5.92492 14.1666 3.33325 9.99998 3.33325ZM9.99998 7.49992C11.15 7.49992 12.0833 8.43325 12.0833 9.58325C12.0833 10.7333 11.15 11.6666 9.99998 11.6666C8.84998 11.6666 7.91665 10.7333 7.91665 9.58325C7.91665 8.43325 8.84998 7.49992 9.99998 7.49992ZM9.99998 5.83325C7.93331 5.83325 6.24998 7.51659 6.24998 9.58325C6.24998 11.6499 7.93331 13.3333 9.99998 13.3333C12.0666 13.3333 13.75 11.6499 13.75 9.58325C13.75 7.51659 12.0666 5.83325 9.99998 5.83325Z" />
      </g>
      <defs>
        <clipPath id="clip0_852_14285">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
