import { SVGProps } from "react";
export default function IconArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.53244 7.26651L10.0045 10.8018L13.4765 7.26651C13.8255 6.91116 14.3893 6.91116 14.7383 7.26651C15.0872 7.62187 15.0872 8.1959 14.7383 8.55125L10.6309 12.7335C10.2819 13.0888 9.71812 13.0888 9.36913 12.7335L5.26174 8.55125C4.91275 8.1959 4.91275 7.62187 5.26174 7.26651C5.61074 6.92027 6.18345 6.91116 6.53244 7.26651Z" />
    </svg>
  );
}
