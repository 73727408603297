import { SVGProps } from "react";
export default function IconArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.26651 13.4676L10.8018 9.99553L7.26651 6.52349C6.91116 6.1745 6.91116 5.61074 7.26651 5.26174C7.62187 4.91275 8.1959 4.91275 8.55125 5.26174L12.7335 9.36913C13.0888 9.71812 13.0888 10.2819 12.7335 10.6309L8.55125 14.7383C8.1959 15.0872 7.62187 15.0872 7.26651 14.7383C6.92027 14.3893 6.91116 13.8166 7.26651 13.4676Z" />
    </svg>
  );
}
