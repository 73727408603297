import { SVGProps } from "react";
export default function IconArrowUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.53244 12.7335L10.0045 9.19818L13.4765 12.7335C13.8255 13.0888 14.3893 13.0888 14.7383 12.7335C15.0872 12.3781 15.0872 11.8041 14.7383 11.4487L10.6309 7.26651C10.2819 6.91116 9.71812 6.91116 9.36913 7.26651L5.26174 11.4487C4.91275 11.8041 4.91275 12.3781 5.26174 12.7335C5.61074 13.0797 6.18345 13.0888 6.53244 12.7335Z" />
    </svg>
  );
}
