import { SVGProps } from "react";
export default function IconArrowNextPage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.2665 13.4676L13.8018 9.99553L10.2665 6.52349C9.91116 6.1745 9.91116 5.61074 10.2665 5.26174C10.6219 4.91275 11.1959 4.91275 11.5513 5.26174L15.7335 9.36913C16.0888 9.71812 16.0888 10.2819 15.7335 10.6309L11.5513 14.7383C11.1959 15.0872 10.6219 15.0872 10.2665 14.7383C9.92027 14.3893 9.91116 13.8166 10.2665 13.4676Z" />
      <path d="M4.26651 13.4676L7.80182 9.99553L4.26651 6.52349C3.91116 6.1745 3.91116 5.61074 4.26651 5.26174C4.62187 4.91275 5.1959 4.91275 5.55125 5.26174L9.73349 9.36913C10.0888 9.71812 10.0888 10.2819 9.73349 10.6309L5.55125 14.7383C5.1959 15.0872 4.62187 15.0872 4.26651 14.7383C3.92027 14.3893 3.91116 13.8166 4.26651 13.4676Z" />
    </svg>
  );
}
