import { SVGProps } from "react";
export default function VisibilityOff(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_852_14262)">
        <path d="M9.99998 5.00008C13.1583 5.00008 15.975 6.77508 17.35 9.58341C16.8583 10.6001 16.1666 11.4751 15.3416 12.1834L16.5166 13.3584C17.675 12.3334 18.5916 11.0501 19.1666 9.58341C17.725 5.92508 14.1666 3.33341 9.99998 3.33341C8.94165 3.33341 7.92498 3.50008 6.96665 3.80841L8.34165 5.18341C8.88331 5.07508 9.43331 5.00008 9.99998 5.00008ZM9.10831 5.95008L10.8333 7.67508C11.3083 7.88341 11.6916 8.26675 11.9 8.74175L13.625 10.4667C13.6916 10.1834 13.7416 9.88341 13.7416 9.57508C13.75 7.50841 12.0666 5.83341 9.99998 5.83341C9.69165 5.83341 9.39998 5.87508 9.10831 5.95008ZM1.67498 3.22508L3.90831 5.45842C2.54998 6.52508 1.47498 7.94175 0.833313 9.58341C2.27498 13.2417 5.83331 15.8334 9.99998 15.8334C11.2666 15.8334 12.4833 15.5917 13.6 15.1501L16.45 18.0001L17.625 16.8251L2.84998 2.04175L1.67498 3.22508ZM7.92498 9.47508L10.1 11.6501C10.0666 11.6584 10.0333 11.6667 9.99998 11.6667C8.84998 11.6667 7.91665 10.7334 7.91665 9.58341C7.91665 9.54175 7.92498 9.51675 7.92498 9.47508ZM5.09165 6.64175L6.54998 8.10008C6.35831 8.55841 6.24998 9.05841 6.24998 9.58341C6.24998 11.6501 7.93331 13.3334 9.99998 13.3334C10.525 13.3334 11.025 13.2251 11.475 13.0334L12.2916 13.8501C11.5583 14.0501 10.7916 14.1667 9.99998 14.1667C6.84165 14.1667 4.02498 12.3917 2.64998 9.58341C3.23331 8.39175 4.08331 7.40841 5.09165 6.64175Z" />
      </g>
      <defs>
        <clipPath id="clip0_852_14262">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
