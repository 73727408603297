import { SVGProps } from "react";
export default function IconSwapHoriz(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1183)">
        <path d="M5.825 9.16669L2.5 12.5L5.825 15.8334V13.3334H11.6667V11.6667H5.825V9.16669ZM17.5 7.50002L14.175 4.16669V6.66669H8.33333V8.33335H14.175V10.8334L17.5 7.50002Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1183">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
