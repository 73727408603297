import { SVGProps } from "react";
export default function IconArrowPreviousPage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.73349 13.4676L6.19818 9.99553L9.73349 6.52349C10.0888 6.1745 10.0888 5.61074 9.73349 5.26174C9.37813 4.91275 8.8041 4.91275 8.44875 5.26174L4.26651 9.36913C3.91116 9.71812 3.91116 10.2819 4.26651 10.6309L8.44875 14.7383C8.8041 15.0872 9.37813 15.0872 9.73349 14.7383C10.0797 14.3893 10.0888 13.8166 9.73349 13.4676Z" />
      <path d="M15.7335 13.4676L12.1982 9.99553L15.7335 6.52349C16.0888 6.1745 16.0888 5.61074 15.7335 5.26174C15.3781 4.91275 14.8041 4.91275 14.4487 5.26174L10.2665 9.36913C9.91116 9.71812 9.91116 10.2819 10.2665 10.6309L14.4487 14.7383C14.8041 15.0872 15.3781 15.0872 15.7335 14.7383C16.0797 14.3893 16.0888 13.8166 15.7335 13.4676Z" />
    </svg>
  );
}
