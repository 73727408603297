import { SVGProps } from "react";
export default function IconCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_704)">
        <path d="M7.08471 13.4676L3.29164 9.73602L2 10.9978L7.08471 16L18 5.26174L16.7175 4L7.08471 13.4676Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_704">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
