import { SVGProps } from "react";
export default function IconMonetizationOn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1197)">
        <path d="M9.99996 1.66669C5.39996 1.66669 1.66663 5.40002 1.66663 10C1.66663 14.6 5.39996 18.3334 9.99996 18.3334C14.6 18.3334 18.3333 14.6 18.3333 10C18.3333 5.40002 14.6 1.66669 9.99996 1.66669ZM9.99996 16.6667C6.32496 16.6667 3.33329 13.675 3.33329 10C3.33329 6.32502 6.32496 3.33335 9.99996 3.33335C13.675 3.33335 16.6666 6.32502 16.6666 10C16.6666 13.675 13.675 16.6667 9.99996 16.6667ZM10.2583 9.28335C8.78329 8.90835 8.30829 8.50002 8.30829 7.89169C8.30829 7.19169 8.96663 6.70002 10.0583 6.70002C11.2083 6.70002 11.6416 7.25002 11.675 8.06669H13.1C13.0583 6.95002 12.375 5.92502 11.025 5.59169V4.16669H9.08329V5.57502C7.82496 5.84169 6.81663 6.65835 6.81663 7.91669C6.81663 9.40835 8.05829 10.1584 9.86663 10.5917C11.4916 10.975 11.8166 11.55 11.8166 12.15C11.8166 12.5917 11.4916 13.3084 10.0666 13.3084C8.73329 13.3084 8.20829 12.7084 8.13329 11.9417H6.69996C6.78329 13.3584 7.83329 14.1584 9.08329 14.4167V15.8334H11.0333V14.4417C12.3 14.2 13.3 13.475 13.3083 12.1334C13.3 10.3 11.725 9.66669 10.2583 9.28335Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1197">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
