"use client";

import { SVGProps } from "react";
import { ICON_MAP, IconName } from "./svgs/iconMap";
import "../../../styles/index.css";

interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
  fill?: string;
  size?: string | number;
}

export function Icon({ name, fill, size = 20, ...restProps }: IconProps) {
  const SVGIcon = ICON_MAP[name];

  return <SVGIcon fill={fill} width={size} height={size} {...restProps} />;
}
