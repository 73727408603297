import { SVGProps } from "react";
export default function IconTooltip(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_703)">
        <path d="M10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 10 2ZM10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4ZM9.2 14C9.2 13.5582 9.55817 13.2 10 13.2C10.4418 13.2 10.8 13.5582 10.8 14C10.8 14.4418 10.4418 14.8 10 14.8C9.55817 14.8 9.2 14.4418 9.2 14ZM10.488 5.232C8.84 4.992 7.384 6.008 6.944 7.464C6.8 7.928 7.152 8.4 7.64 8.4H7.8C8.128 8.4 8.392 8.168 8.504 7.864C8.76 7.152 9.52 6.664 10.344 6.84C11.104 7 11.664 7.744 11.6 8.52C11.52 9.592 10.304 9.824 9.64 10.824C9.64 10.832 9.632 10.832 9.632 10.84C9.624 10.856 9.616 10.864 9.608 10.88C9.536 11 9.464 11.136 9.408 11.28C9.4 11.304 9.384 11.32 9.376 11.344C9.368 11.36 9.368 11.376 9.36 11.4C9.264 11.672 9.2 12 9.2 12.4H10.8C10.8 12.064 10.888 11.784 11.024 11.544C11.04 11.52 11.048 11.496 11.064 11.472C11.128 11.36 11.208 11.256 11.288 11.16C11.296 11.152 11.304 11.136 11.312 11.128C11.392 11.032 11.48 10.944 11.576 10.856C12.344 10.128 13.384 9.536 13.168 8.008C12.976 6.616 11.88 5.44 10.488 5.232Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_703">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
