import { SVGProps } from "react";
export default function IconSettingsRemote(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1187)">
        <path d="M12.5 7.5H7.49996C7.04163 7.5 6.66663 7.875 6.66663 8.33333V18.3333C6.66663 18.7917 7.04163 19.1667 7.49996 19.1667H12.5C12.9583 19.1667 13.3333 18.7917 13.3333 18.3333V8.33333C13.3333 7.875 12.9583 7.5 12.5 7.5ZM11.6666 17.5H8.33329V9.16667H11.6666V17.5Z" />
        <path d="M9.99996 11.6667C10.4602 11.6667 10.8333 11.2936 10.8333 10.8333C10.8333 10.3731 10.4602 10 9.99996 10C9.53972 10 9.16663 10.3731 9.16663 10.8333C9.16663 11.2936 9.53972 11.6667 9.99996 11.6667Z" />
        <path d="M5.87506 5.04167L7.05006 6.21667C7.80839 5.46667 8.85006 5 10.0001 5C11.1501 5 12.1917 5.46667 12.9501 6.21667L14.1251 5.04167C13.0667 3.98333 11.6084 3.33333 10.0001 3.33333C8.39172 3.33333 6.93339 3.98333 5.87506 5.04167ZM10.0001 0C7.46672 0 5.17506 1.025 3.51672 2.68333L4.69172 3.85833C6.05006 2.50833 7.92506 1.66667 10.0001 1.66667C12.0751 1.66667 13.9501 2.50833 15.3001 3.86667L16.4751 2.69167C14.8251 1.025 12.5334 0 10.0001 0Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1187">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
