import { SVGProps } from "react";
export default function IconPower(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1211)">
        <path d="M13.3333 7.5V11.3833L10.4167 14.3083V15.8333H9.58333V14.3083L6.66667 11.375V7.5H13.3333ZM13.3333 2.5H11.6667V5.83333H8.33333V2.5H6.66667V5.83333H6.65833C5.75 5.825 5 6.575 5 7.48333V12.0833L7.91667 15V17.5H12.0833V15L15 12.075V7.5C15 6.58333 14.25 5.83333 13.3333 5.83333V2.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1211">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
