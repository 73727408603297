import { SVGProps } from "react";
export default function IconLibraryAddCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1201)">
        <path d="M16.6666 3.33335V13.3334H6.66663V3.33335H16.6666ZM16.6666 1.66669H6.66663C5.74996 1.66669 4.99996 2.41669 4.99996 3.33335V13.3334C4.99996 14.25 5.74996 15 6.66663 15H16.6666C17.5833 15 18.3333 14.25 18.3333 13.3334V3.33335C18.3333 2.41669 17.5833 1.66669 16.6666 1.66669ZM10.3916 11.6667L7.49996 8.75002L8.66663 7.57502L10.3916 9.30835L14.6666 5.00002L15.8333 6.17502L10.3916 11.6667ZM3.33329 5.00002H1.66663V16.6667C1.66663 17.5834 2.41663 18.3334 3.33329 18.3334H15V16.6667H3.33329V5.00002Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1201">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
