import { SVGProps } from "react";
export default function IconInformation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_702)">
        <path d="M9.2 6.8C9.2 6.35817 9.55817 6 10 6C10.4418 6 10.8 6.35817 10.8 6.8C10.8 7.24183 10.4418 7.6 10 7.6C9.55817 7.6 9.2 7.24183 9.2 6.8ZM10 14C10.44 14 10.8 13.64 10.8 13.2V9.52C10.8 9.08 10.44 8.72 10 8.72C9.56 8.72 9.2 9.08 9.2 9.52V13.2C9.2 13.64 9.56 14 10 14ZM10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 10 2ZM10 16.4C6.472 16.4 3.6 13.528 3.6 10C3.6 6.472 6.472 3.6 10 3.6C13.528 3.6 16.4 6.472 16.4 10C16.4 13.528 13.528 16.4 10 16.4Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_702">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
