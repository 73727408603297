import { SVGProps } from "react";
export default function IconClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_705)">
        <path d="M17 4.41L15.59 3L10 8.59L4.41 3L3 4.41L8.59 10L3 15.59L4.41 17L10 11.41L15.59 17L17 15.59L11.41 10L17 4.41Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_705">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
