import { SVGProps } from "react";
export default function IconHome(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3269_1063)">
        <path d="M9.99996 4.74167L14.1666 8.49167V15H12.5V10H7.49996V15H5.83329V8.49167L9.99996 4.74167ZM9.99996 2.5L1.66663 10H4.16663V16.6667H9.16663V11.6667H10.8333V16.6667H15.8333V10H18.3333L9.99996 2.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_3269_1063">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
