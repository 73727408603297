import { SVGProps } from "react";
export default function IconWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_843_2006"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.5" width="20" height="20" fill="white" />
      </mask>
      <g mask="url(#mask0_843_2006)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1356 15.3613C10.0198 15.3133 9.91447 15.243 9.82579 15.1543C9.7371 15.0656 9.66675 14.9603 9.61875 14.8444C9.57075 14.7286 9.54605 14.6044 9.54605 14.4789C9.54605 14.3535 9.57075 14.2293 9.61875 14.1134C9.66675 13.9976 9.7371 13.8923 9.82579 13.8036C9.91447 13.7149 10.0198 13.6446 10.1356 13.5966C10.2515 13.5486 10.3757 13.5239 10.5011 13.5239C10.7544 13.5239 10.9974 13.6245 11.1765 13.8036C11.3556 13.9827 11.4562 14.2256 11.4562 14.4789C11.4562 14.7322 11.3556 14.9752 11.1765 15.1543C10.9974 15.3334 10.7544 15.434 10.5011 15.434C10.3757 15.434 10.2515 15.4093 10.1356 15.3613ZM9.93834 6.92575C10.0876 6.77649 10.29 6.69263 10.5011 6.69263C10.7122 6.69263 10.9147 6.77649 11.0639 6.92575C11.2132 7.07501 11.297 7.27745 11.297 7.48853V11.468C11.297 11.6791 11.2132 11.8816 11.0639 12.0308C10.9147 12.1801 10.7122 12.2639 10.5011 12.2639C10.29 12.2639 10.0876 12.1801 9.93834 12.0308C9.78908 11.8816 9.70523 11.6791 9.70523 11.468V7.48853C9.70523 7.27745 9.78908 7.07501 9.93834 6.92575ZM12.1558 2.95508C11.4204 1.68164 9.58186 1.68164 8.84645 2.95508L1.81466 15.1348C1.08005 16.4082 1.99772 18 3.46934 18H17.5329C19.0037 18 19.9222 16.4082 19.1876 15.1348L12.1558 2.95508Z"
        />
      </g>
    </svg>
  );
}
