import { SVGProps } from "react";
export default function IconSuccessCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20Z"
        fill="#3AB400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4938 13.6393C31.1745 14.3368 31.1608 15.4541 30.4633 16.1348L19.9532 26.3912C19.2677 27.0602 18.1737 27.0602 17.4882 26.3912L11.0752 20.1331C10.3777 19.4524 10.364 18.3351 11.0447 17.6376C11.7254 16.94 12.8427 16.9264 13.5402 17.6071L18.7207 22.6625L27.9983 13.6088C28.6958 12.9281 29.8131 12.9418 30.4938 13.6393Z"
        fill="white"
      />
    </svg>
  );
}
