import { SVGProps } from "react";
export default function CloudUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_765_2785)">
        <path d="M32.25 16.7333C31.1167 10.9833 26.0667 6.66666 20 6.66666C15.1833 6.66666 11 9.39999 8.91667 13.4C3.9 13.9333 0 18.1833 0 23.3333C0 28.85 4.48333 33.3333 10 33.3333H31.6667C36.2667 33.3333 40 29.6 40 25C40 20.6 36.5833 17.0333 32.25 16.7333ZM31.6667 30H10C6.31667 30 3.33333 27.0167 3.33333 23.3333C3.33333 19.9167 5.88333 17.0667 9.26667 16.7167L11.05 16.5333L11.8833 14.95C13.4667 11.9 16.5667 9.99999 20 9.99999C24.3667 9.99999 28.1333 13.1 28.9833 17.3833L29.4833 19.8833L32.0333 20.0667C34.6333 20.2333 36.6667 22.4167 36.6667 25C36.6667 27.75 34.4167 30 31.6667 30ZM13.3333 21.6667H17.5833V26.6667H22.4167V21.6667H26.6667L20 15L13.3333 21.6667Z" />
      </g>
      <defs>
        <clipPath id="clip0_765_2785">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
