import { SVGProps } from "react";
export default function IconAdd(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1219_1555)">
        <path d="M11.2576 7.08332C11.2576 5.47186 9.90151 4.16666 8.22727 4.16666C6.55303 4.16666 5.19697 5.47186 5.19697 7.08332C5.19697 8.69478 6.55303 9.99999 8.22727 9.99999C9.90151 9.99999 11.2576 8.69478 11.2576 7.08332ZM9.74242 7.08332C9.74242 7.88541 9.06061 8.54166 8.22727 8.54166C7.39394 8.54166 6.71212 7.88541 6.71212 7.08332C6.71212 6.28124 7.39394 5.62499 8.22727 5.62499C9.06061 5.62499 9.74242 6.28124 9.74242 7.08332ZM2.16667 14.375V15.8333H14.2879V14.375C14.2879 12.4354 10.25 11.4583 8.22727 11.4583C6.20454 11.4583 2.16667 12.4354 2.16667 14.375ZM3.68182 14.375C3.83333 13.8573 6.18182 12.9167 8.22727 12.9167C10.2652 12.9167 12.6061 13.85 12.7727 14.375H3.68182ZM16.5606 12.1875V9.99999H18.8333V8.54166H16.5606V6.35416H15.0455V8.54166H12.7727V9.99999H15.0455V12.1875H16.5606Z" />
      </g>
      <defs>
        <clipPath id="clip0_1219_1555">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
