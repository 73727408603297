import { SVGProps } from "react";
export default function IconAccountCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3090_1219)">
        <path d="M9.99996 1.66666C5.39996 1.66666 1.66663 5.39999 1.66663 9.99999C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.39999 14.6 1.66666 9.99996 1.66666ZM5.89163 15.2333C6.24996 14.4833 8.43329 13.75 9.99996 13.75C11.5666 13.75 13.7583 14.4833 14.1083 15.2333C12.975 16.1333 11.55 16.6667 9.99996 16.6667C8.44996 16.6667 7.02496 16.1333 5.89163 15.2333ZM15.3 14.025C14.1083 12.575 11.2166 12.0833 9.99996 12.0833C8.78329 12.0833 5.89163 12.575 4.69996 14.025C3.84996 12.9083 3.33329 11.5167 3.33329 9.99999C3.33329 6.32499 6.32496 3.33332 9.99996 3.33332C13.675 3.33332 16.6666 6.32499 16.6666 9.99999C16.6666 11.5167 16.15 12.9083 15.3 14.025ZM9.99996 4.99999C8.38329 4.99999 7.08329 6.29999 7.08329 7.91666C7.08329 9.53332 8.38329 10.8333 9.99996 10.8333C11.6166 10.8333 12.9166 9.53332 12.9166 7.91666C12.9166 6.29999 11.6166 4.99999 9.99996 4.99999ZM9.99996 9.16666C9.30829 9.16666 8.74996 8.60832 8.74996 7.91666C8.74996 7.22499 9.30829 6.66666 9.99996 6.66666C10.6916 6.66666 11.25 7.22499 11.25 7.91666C11.25 8.60832 10.6916 9.16666 9.99996 9.16666Z" />
      </g>
      <defs>
        <clipPath id="clip0_3090_1219">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
