import { SVGProps } from "react";
export default function IconNotification(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_708)">
        <path d="M10 19C10.9625 19 11.75 18.2154 11.75 17.2564H8.25C8.25 18.2154 9.0375 19 10 19ZM15.25 13.7692V9.41026C15.25 6.73385 13.8237 4.49333 11.3125 3.90051V3.30769C11.3125 2.5841 10.7263 2 10 2C9.27375 2 8.6875 2.5841 8.6875 3.30769V3.90051C6.185 4.49333 4.75 6.72513 4.75 9.41026V13.7692L3 15.5128V16.3846H17V15.5128L15.25 13.7692ZM13.5 14.641H6.5V9.41026C6.5 7.24821 7.82125 5.48718 10 5.48718C12.1788 5.48718 13.5 7.24821 13.5 9.41026V14.641Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_708">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
