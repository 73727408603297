import CloudUpload from "./components/CloudUpload";
import IconAccountCircle from "./components/IconAccountCircle";
import IconAdd from "./components/IconAdd";
import IconArrowDown from "./components/IconArrowDown";
import IconArrowLeft from "./components/IconArrowLeft";
import IconArrowNextPage from "./components/IconArrowNextPage";
import IconArrowPreviousPage from "./components/IconArrowPreviousPage";
import IconArrowRight from "./components/IconArrowRight";
import IconArrowRightTail from "./components/IconArrowRightTail";
import IconArrowUp from "./components/IconArrowUp";
import IconCalendar from "./components/IconCalendar";
import IconCancel from "./components/IconCancel";
import IconCancelRound from "./components/IconCancelRound";
import IconCheck from "./components/IconCheck";
import IconCheckboxChecked from "./components/IconCheckboxChecked";
import IconCheckboxIndeterminate from "./components/IconCheckboxIndeterminate";
import IconCheckboxUnchecked from "./components/IconCheckboxUnchecked";
import IconClock from "./components/IconClock";
import IconClose from "./components/IconClose";
import IconDate from "./components/IconDate";
import IconDownload from "./components/IconDownload";
import IconEqualizer from "./components/IconEqualizer";
import IconExclamation from "./components/IconExclamation";
import IconFilter from "./components/IconFilter";
import IconHome from "./components/IconHome";
import IconInformation from "./components/IconInformation";
import IconLibraryAddCheck from "./components/IconLibraryAddCheck";
import IconLogout from "./components/IconLogout";
import IconMonetizationOn from "./components/IconMonetizationOn";
import IconNotification from "./components/IconNotification";
import IconNotificationFill from "./components/IconNotificationFill";
import IconNotificationNew from "./components/IconNotificationNew";
import IconNotificationNewFill from "./components/IconNotificationNewFill";
import IconPower from "./components/IconPower";
import IconRadioChecked from "./components/IconRadioChecked";
import IconRadioUnchecked from "./components/IconRadioUnchecked";
import IconRefresh from "./components/IconRefresh";
import IconSave from "./components/IconSave";
import IconSearch from "./components/IconSearch";
import IconSettingsRemote from "./components/IconSettingsRemote";
import IconSuccessCircle from "./components/IconSuccessCircle";
import IconSwapHoriz from "./components/IconSwapHoriz";
import IconTooltip from "./components/IconTooltip";
import IconTrendingUp from "./components/IconTrendingUp";
import IconWarning from "./components/IconWarning";
import Visibility from "./components/Visibility";
import VisibilityOff from "./components/VisibilityOff";

export const ICON_MAP = {
  "cloud-upload": CloudUpload,
  "icon-account-circle": IconAccountCircle,
  "icon-add": IconAdd,
  "icon-arrow-down": IconArrowDown,
  "icon-arrow-left": IconArrowLeft,
  "icon-arrow-next-page": IconArrowNextPage,
  "icon-arrow-previous-page": IconArrowPreviousPage,
  "icon-arrow-right": IconArrowRight,
  "icon-arrow-right-tail": IconArrowRightTail,
  "icon-arrow-up": IconArrowUp,
  "icon-calendar": IconCalendar,
  "icon-cancel": IconCancel,
  "icon-cancel-round": IconCancelRound,
  "icon-check": IconCheck,
  "icon-checkbox-checked": IconCheckboxChecked,
  "icon-checkbox-indeterminate": IconCheckboxIndeterminate,
  "icon-checkbox-unchecked": IconCheckboxUnchecked,
  "icon-clock": IconClock,
  "icon-close": IconClose,
  "icon-date": IconDate,
  "icon-download": IconDownload,
  "icon-equalizer": IconEqualizer,
  "icon-exclamation": IconExclamation,
  "icon-filter": IconFilter,
  "icon-home": IconHome,
  "icon-information": IconInformation,
  "icon-library-add-check": IconLibraryAddCheck,
  "icon-logout": IconLogout,
  "icon-monetization-on": IconMonetizationOn,
  "icon-notification": IconNotification,
  "icon-notification-fill": IconNotificationFill,
  "icon-notification-new": IconNotificationNew,
  "icon-notification-new-fill": IconNotificationNewFill,
  "icon-power": IconPower,
  "icon-radio-checked": IconRadioChecked,
  "icon-radio-unchecked": IconRadioUnchecked,
  "icon-refresh": IconRefresh,
  "icon-save": IconSave,
  "icon-search": IconSearch,
  "icon-settings-remote": IconSettingsRemote,
  "icon-success-circle": IconSuccessCircle,
  "icon-swap-horiz": IconSwapHoriz,
  "icon-tooltip": IconTooltip,
  "icon-trending-up": IconTrendingUp,
  "icon-warning": IconWarning,
  visibility: Visibility,
  "visibility-off": VisibilityOff,
} as const;

export type IconName = keyof typeof ICON_MAP;
