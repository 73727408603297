import { SVGProps } from "react";
export default function IconCalendar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_534_701)">
        <path d="M15.6 3.4H14.9V2H13.5V3.4H6.5V2H5.1V3.4H4.4C3.63 3.4 3 4.03 3 4.8V16C3 16.77 3.63 17.4 4.4 17.4H15.6C16.37 17.4 17 16.77 17 16V4.8C17 4.03 16.37 3.4 15.6 3.4ZM15.6 16H4.4V8.3H15.6V16ZM15.6 6.9H4.4V4.8H15.6V6.9Z" />
      </g>
      <defs>
        <clipPath id="clip0_534_701">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
