import { SVGProps } from "react";
export default function IconArrowRightTail(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.9084 0.758276C10.5834 1.08328 10.5834 1.60828 10.9084 1.93328L14.1417 5.16661H1.50002C1.04169 5.16661 0.666687 5.54161 0.666687 5.99994C0.666687 6.45828 1.04169 6.83328 1.50002 6.83328H14.15L10.9167 10.0666C10.5917 10.3916 10.5917 10.9166 10.9167 11.2416C11.2417 11.5666 11.7667 11.5666 12.0917 11.2416L16.75 6.58328C17.075 6.25828 17.075 5.73328 16.75 5.40828L12.0834 0.758276C11.7667 0.433276 11.2334 0.433276 10.9084 0.758276Z" />
    </svg>
  );
}
