import { SVGProps } from "react";
export default function IconClock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2708_2223)">
        <path d="M9.99163 1.66667C5.39163 1.66667 1.66663 5.40001 1.66663 10C1.66663 14.6 5.39163 18.3333 9.99163 18.3333C14.6 18.3333 18.3333 14.6 18.3333 10C18.3333 5.40001 14.6 1.66667 9.99163 1.66667ZM9.99996 16.6667C6.31663 16.6667 3.33329 13.6833 3.33329 10C3.33329 6.31667 6.31663 3.33334 9.99996 3.33334C13.6833 3.33334 16.6666 6.31667 16.6666 10C16.6666 13.6833 13.6833 16.6667 9.99996 16.6667ZM10.4166 5.83334H9.16663V10.8333L13.5416 13.4583L14.1666 12.4333L10.4166 10.2083V5.83334Z" />
      </g>
      <defs>
        <clipPath id="clip0_2708_2223">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
